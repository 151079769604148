import React from 'react'
import Jdenticon from 'react-jdenticon';
// Docs:: https://react-type-animation.netlify.app/
import { TypeAnimation } from 'react-type-animation';

const Messages = props => {
	var intervalId;

	const start = (el, total_msg) => {
		//console.log('start total_msg', total_msg);
		if (total_msg > 1)
			handleScrollToBottom();
		intervalId = setInterval(handleScrollToBottom, 500);
		props.onTyping(true);
	}

	const end = (el, total_msg) => {
		el.classList.remove('index-module_type__E-SaG');
		clearInterval(intervalId);
		if (total_msg > 1)
			handleScrollToBottom();
		//const txt = document.getElementById('userChat');
		//txt.focus();
		props.onTyping(false);
	}

	const handleScrollToBottom = () => {
		//const scrollingElement = (document.scrollingElement || document.body);
		//scrollingElement.scrollTop = scrollingElement.scrollHeight;

		//var divElement = document.getElementById('bottom-msg');
		//divElement.scrollIntoView();

		var element = document.getElementById("top-container");
		element.scrollTop = element.scrollHeight;

	};

	const deleteClick = (id, index) => {
		props.onDelete(id, index);
	}

	const editClick = (id, index) => {
		props.onEdit(id, index);
	}

	const saveClick = (id, index) => {
		props.onSave(id, index);
	}

	const cancelClick = (id, index) => {
		props.onCancel(id, index);
	}

	const onChange = event => {
		props.onNewMessageChange(event.target.value);
	};

	const items = props.msg;
	const total_msg = items.length;
	if (items === null) return '';
	const listItems = items.map(
		(item, index) => {
			//console.log('typing',item.typing);
			return item.user === 'Bot' ? (

				<div data-itemkey={item.id.toString()} key={/*item.id.toString()*/index.toString()} className="flex w-full mt-8">
					<div className="container lg:w-[1024px] mx-auto flex px-4 flex-wrap">
						<div className="text-2xl">
							<div className="flex">
								<div className="w-10 h-10 me-4">
									<div className="rounded bg-gray-300 w-10 h-10 items-center justify-center flex  overflow-hidden mb-1">
										<img className="max-w-full object-cover h-10 w-10" src="https://vnoclogos.s3-us-west-1.amazonaws.com/avatar-cai-2.png" alt="" />
									</div>
									<div className='text-xs text-center text-black'>
										CAI
									</div>
								</div>
								<div className='bg-gray-100/50 rounded-lg px-8 py-6 break-words'>
									{
										item.typing ? <TypeAnimation sequence={[(e) => start(e, total_msg), item.message, 10, (el) => end(el, total_msg)]} speed={75} />
											: item.message
									}
								</div>
							</div>
						</div>
					</div>
				</div>
			) : (
				<div data-itemkey={item.id.toString()} key={/*item.id.toString()*/index.toString()} className="flex w-full mt-8">
					<div className="container lg:w-[1024px] mx-auto flex px-4 flex-wrap">
						<div className="w-full">
							<div className="flex w-full">
								<div className="w-10 h-10 me-4">
									<div className="rounded bg-gray-300 w-10 h-10 overflow-hidden d-flex justify-center items-center custom-icon-avatar">
										<Jdenticon size="30" value={props.cookieValue} />
									</div>
								</div>
								<div className="relative bg-gray-100/50 rounded-lg px-8 py-6 pe-20 break-words">
									{
										!props.editing ? (
											<div className="absolute right-3 top-4 space-x-2">
												<button onClick={() => editClick(item.rec_id.toString(), index.toString())} className="w-6 h-6 text-gray-400 hover:text-gray-700 p-1.5 hover:shadow-sm bg-gray-100 hover:bg-gray-300 inline-flex items-center justify-center rounded cursor-pointer">
													<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
														<path strokeLinecap="round" strokeLinejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" />
													</svg>
												</button>
												<button onClick={() => deleteClick(item.rec_id.toString(), index.toString())} className="w-6 h-6 text-white/60 p-1.5 hover:shadow-sm bg-red-100 hover:text-white  hover:bg-red-700 inline-flex items-center justify-center rounded cursor-pointer">
													<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-5 h-5">
														<path strokeLinecap="round" strokeLinejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
													</svg>
												</button>
											</div>
										) : ''
									}

									{
										props.editing && props.editing === index.toString() ? (
											<div className='w-full'>
												<textarea className='form-control' name="newMessage" value={props.newMessage} onChange={onChange} />
												<div className='space-x-2 mt-1'>
													<button className='text-xs inline-block py-1 px-2 cursor-pointer border border-gray-300 bg-blue-600/80 text-white rounded-sm' onClick={() => saveClick(item.rec_id.toString(), index.toString())}>Save</button>
													<button className='text-xs inline-block py-1 px-2 cursor-pointer border border-gray-300 rounded-sm hover:bg-gray-200' onClick={() => cancelClick(item.rec_id.toString(), index.toString())}>Cancel</button>
												</div>
												{
													props.updatingMsg && props.editing === index.toString() ? (
														<div className='text-xs flex mt-1 w-full items-center'>
															<svg className='w-8 h-8 me-2' version="1.1" id="L9" x="0px" y="0px" viewBox="0 0 100 100" enableBackground="new 0 0 0 0">
																<path fill="#2563ebcc" d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50">
																	<animateTransform
																		attributeName="transform"
																		attributeType="XML"
																		type="rotate"
																		dur="1s"
																		from="0 50 50"
																		to="360 50 50"
																		repeatCount="indefinite" />
																</path>
															</svg>
															<span>Saving...</span>
														</div>
													) : ''
												}
											</div>
										) : (<div className="text-2xl">{item.message}</div>)

									}
									{
										props.deletingMsg && props.deletingMsg === index.toString() ? (
											<div className='w-full'>
												<div className='text-xs flex mt-1 w-full items-center'>
													<svg className='w-8 h-8 me-2' version="1.1" id="L9" x="0px" y="0px" viewBox="0 0 100 100" enableBackground="new 0 0 0 0">
														<path fill="#2563ebcc" d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50">
															<animateTransform
																attributeName="transform"
																attributeType="XML"
																type="rotate"
																dur="1s"
																from="0 50 50"
																to="360 50 50"
																repeatCount="indefinite" />
														</path>
													</svg>
													<span> Deleting...</span>
												</div>
											</div>
										) : ''
									}
								</div>
							</div>
						</div>
					</div>
				</div>
			)
		}
	);

	return (
		<>
			{listItems}
			{
				props.waiting ? (
					<div className="flex w-full py-8">
						<div className="container lg:w-[1024px] mx-auto flex px-4 flex-wrap">
							<div className="text-2xl">
								<div className="flex">
									<div className="w-10 h-10 me-4">
										<div className="rounded bg-gray-300 w-10 h-10 items-center justify-center flex  overflow-hidden mb-1">
											<img className="max-w-full object-cover h-10 w-10" src="https://vnoclogos.s3-us-west-1.amazonaws.com/avatar-cai-2.png" alt="" />
										</div>
										<div className='text-xs text-center text-black'>
											CAI
										</div>
									</div>
									<div>
										<TypeAnimation sequence={['', 10]} speed={40} />
									</div>
								</div>
							</div>
						</div>
					</div>
				) : ''
			}
		</>
	)
}

export default Messages