import "./assets/css/custom.css"
//import Header from './components/includes/Header';
import Footer from './components/includes/Footer';
import Chat from './components/Chat';

function App() {
  return (
    <>
      <div id="top-container" className="cai-main-wrapper relative overflow-y-auto max-h-[500px] text-black">
        {/*<Header />*/}
        <Chat />
        <Footer />
      </div>
    </>
  );
}

export default App;
