import React, { Component } from 'react';
import axios from 'axios'
import Messages from './Messages';

// DOCS:: https://github.com/Andarist/react-textarea-autosize
import TextareaAutosize from 'react-textarea-autosize';

const initialState = {
	id: 1,
	rec_id: '',
	cookieValue: '',
	isLoading: true,
	//data: null,
	error: null,
	userChat: '',
	response: "How's your day going?",
	waiting: false,
	typing: false,
	allMessages: null,
	updatingMsg: false,
	deletingMsg: false,
	editing: false,
	newMessage: ''
}
class Chat extends Component {
	state = initialState;
	_domain = window.location.hostname.replace('www.', '');
	_url = 'https://openai.contrib.co/request';
	//_url = 'http://54.151.61.119:5000';
	//_url = 'http://localhost:5000';
	//_url = 'https://openai.contrib.com/request';
	


	componentDidMount() {
		const greetings = [
			"How's your day going?",
			"How are you today?",
			"How's it going?",
			"How's everything going for you?",
			"What do you like to do in your free time?",
			"What's up?",
			"How have you been?",
			"How's your day been so far?",
			"How are you doing today?",
			"How's everything going?",
			"What's new with you?",
			"How's life treating you?"
		];
		const greet = greetings[Math.floor(Math.random() * greetings.length)];

		this.setState((prevState, props) => ({
			response: greet
		}));

		//this.setState((prevState, props) => ({
		//allMessages: [{id:1, typing:false, user:'Bot', message: `Hey there, great to meet you. I'm CAI, your personal Contrib AI.\nMy goal is to be useful, friendly and fun. Ask me for advice, for answers, or let's talk about whatever's on your mind.\n`+greet}]
		//})); 

		var storedCookieValue = this.getCookie('cookieContribAI');
		if (storedCookieValue) {
			this.setState({ cookieValue: storedCookieValue });
		} else {
			storedCookieValue = Date.now().toString(36) + Math.random().toString(36).substr(2, 5);
			this.setState({ cookieValue: storedCookieValue });
			this.setCookie('cookieContribAI', storedCookieValue, 7);
		}
		
		//var domain = window.location.hostname.replace('www.', '');
		const params = new URLSearchParams();
		params.append('uid', storedCookieValue);
		params.append('domain', this._domain);
		axios.post(this._url + '/history', params)
			.then(response => {
				if (response.data.messages) {
					var intro = response.data.intro + " " + greet;
					if (response.data.messages.length > 0) {
						let y = 2;
						let arr_chat = [{ id: 1, rec_id: 0, typing: false, user: 'Bot', message: intro }];

						for (var x = 0; x < response.data.messages.length; x++) {
							const record = response.data.messages[x];
							arr_chat.push({ id: y, rec_id: record.rec_id, typing: false, user: 'guest', message: record.user_chat });
							y++;
							arr_chat.push({ id: y, rec_id: record.rec_id, typing: false, user: 'Bot', message: record.ai_answer });
							y++;
						}
						this.setState((prevState, props) => ({
							id: y
						}));
						this.setState((prevState, props) => ({
							allMessages: arr_chat
						}));

						setTimeout(function () {
							//const scrollingElement = (document.scrollingElement || document.body);
							//scrollingElement.scrollTop = scrollingElement.scrollHeight;

							//var divElement = document.getElementById('bottom-msg');
							//divElement.scrollIntoView();

							var element = document.getElementById("top-container");
							element.scrollTop = element.scrollHeight;

							const txt = document.getElementById('userChat');
							txt.focus();
						}, 500);
					} else {
						this.setState((prevState, props) => ({
							allMessages: [{ id: 1, typing: true, user: 'Bot', message: intro }]
						}));
					}

					this.setState({ isLoading: false/*,typing:false*/ });
				}
			});


		const queryString = window.location.search;
		const urlParams = new URLSearchParams(queryString);
		const queryVal = urlParams.get('q');
		if (queryVal) {
			console.log('queryVal', queryVal);
		}
	}

	onTyping = (nVal) => {
		this.setState({ typing: nVal });
		setTimeout(function () {
			try {
				const txt = document.getElementById('userChat');
				txt.focus();
			} catch (e) {

			}
		}, 1000)
	};

	handleInputChange = (event) => {
		this.setState({ cookieValue: event.target.value });
	};

	handleSaveCookie = () => {
		this.setCookie('cookieContribAI', this.state.cookieValue, 365);
	};

	handleDeleteCookie = () => {
		this.deleteCookie('myCookie');
		this.setState({ cookieValue: '' });
	};

	setCookie = (name, value, days) => {
		const expirationDate = new Date();
		expirationDate.setDate(expirationDate.getDate() + days);
		const cookieValue = escape(value) + ((days == null) ? '' : '; expires=' + expirationDate.toUTCString());
		document.cookie = name + '=' + cookieValue;
	};

	getCookie = (name) => {
		const cookieName = name + '=';
		const decodedCookie = decodeURIComponent(document.cookie);
		const cookieArray = decodedCookie.split(';');
		for (let i = 0; i < cookieArray.length; i++) {
			let cookie = cookieArray[i];
			while (cookie.charAt(0) === ' ') {
				cookie = cookie.substring(1);
			}
			if (cookie.indexOf(cookieName) === 0) {
				return cookie.substring(cookieName.length, cookie.length);
			}
		}
		return '';
	};

	deleteCookie = (name) => {
		document.cookie = name + '=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
	};

	handleChange = event => {
		this.setState({
			[event.target.name]: event.target.value
		});
	};

	send = (e) => {
		//console.log('clicked');
		if (this.state.userChat === '') return;
		const self = this;
		const ID = self.state.id + 1;
		self.setState((prevState, props) => ({
			id: ID
		}));
		self.setState((prevState, props) => ({
			waiting: true
		}));

		self.setState((prevState, props) => ({
			typing: true
		}));

		self.setState({ allMessages: [...self.state.allMessages, { id: ID, typing: true, user: 'guest', message: self.state.userChat }] })

		//var domain = window.location.hostname.replace('www.', '');

		const params = new URLSearchParams();
		params.append('uid', this.state.cookieValue);
		params.append('q', this.state.userChat);
		params.append('prev', this.state.response);
		params.append('domain', this._domain);
		params.append('ref', btoa(window.location.href).split("").reverse().join(""));
		axios.post(this._url + '/ask', params)
			//axios.post(this._url+'/ai', params)
			.then(response => {

				if (response.data.message) {
					const updatedAllMessages = [...self.state.allMessages];
					const mid = response.data.mid;
					updatedAllMessages[updatedAllMessages.length - 1].rec_id = mid;
					self.setState({ allMessages: updatedAllMessages });

					const msg = response.data.message.replace(/\n/g, ' ');

					self.setState({ response: msg });

					const ID = self.state.id + 1;
					self.setState((prevState, props) => ({
						id: ID
					}));

					self.setState((prevState, props) => ({
						waiting: false
					}));

					self.setState((prevState, props) => ({
						id: prevState.id + 1
					}));

					self.setState({ allMessages: [...self.state.allMessages, { id: ID, typing: true, user: 'Bot', message: msg }] }) //simple value

				}
			});
		this.setState({ userChat: "" });
	};

	handleKeyPress = (event) => {
		if (event.key === 'Enter') {
			event.preventDefault();
			this.send();
			setTimeout(function () {
				//const scrollingElement = (document.scrollingElement || document.body);
				//scrollingElement.scrollTop = scrollingElement.scrollHeight;

				//var divElement = document.getElementById('bottom-msg');
				//divElement.scrollIntoView();

				var element = document.getElementById("top-container");
				element.scrollTop = element.scrollHeight;
			}, 300);
			return false;
		}
	}

	cancelMsg = (id, index) => {
		this.setState({ editing: false });
	};

	saveMsg = (id, index) => {
		const self = this;
		const dataToSave = { uid: self.state.cookieValue, domain: self._domain, message: self.state.newMessage };
		self.setState({ updatingMsg: true });
		axios.put(self._url + '/message/' + id, dataToSave)
			.then(response => {
				const updatedAllMessages = [...self.state.allMessages];

				const msg = response.data.message.replace(/\n/g, ' ');
				const ai = response.data.ai.replace(/\n/g, ' ');
				updatedAllMessages[parseInt(index)].message = msg;
				updatedAllMessages[parseInt(index) + 1].message = ai;

				self.setState({ allMessages: updatedAllMessages });
				self.setState({ editing: false });
				self.setState({ updatingMsg: false });
			}).catch(response => {

			})
	};

	deleteMsg = (id, index) => {
		const self = this;
		const dataToDelete = { uid: self.state.cookieValue, domain: self._domain };
		self.setState({ deletingMsg: index });
		axios.delete(self._url + '/message/' + id, { data: dataToDelete })
			.then(response => {
				const updatedAllMessages = [...self.state.allMessages];
				updatedAllMessages.splice(parseInt(index), 2);
				self.setState({ allMessages: updatedAllMessages });
				self.setState({ deletingMsg: false });
			}).catch(esponse => {

			})
	};

	onNewMessageChange = (msg) => {
		this.setState((prevState, props) => ({
			newMessage: msg
		}));
	}

	editMsg = (id, index) => {
		const self = this;
		self.setState({ editing: index });

		const m = this.state.allMessages[parseInt(index)].message;
		this.setState((prevState, props) => ({
			newMessage: m
		}));
	};

	render() {
		const { cookieValue, isLoading, error, typing } = this.state;
		if (isLoading) {
			return (
				<div className="min-h-[100vh] pt-[120px] pb-[88px] flex flex-col">
					<div className="flex w-full">
						<div className="container lg:w-[1024px] mx-auto flex px-4 flex-wrap align-items-center justify-center">
							<div>
								<svg className="loading-svg" version="1.1" id="L9" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
									viewBox="0 0 100 100" enableBackground="new 0 0 0 0">
									<path fill="#39B54A" d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50">
										<animateTransform
											attributeName="transform"
											attributeType="XML"
											type="rotate"
											dur="1s"
											from="0 50 50"
											to="360 50 50"
											repeatCount="indefinite" />
									</path>
								</svg>
							</div>
						</div>
					</div>
				</div>
			)
		}

		if (error) {
			return <div>Error: {error}</div>;
		}

		return (
			<>
				<div className="pb-[88px] flex flex-col">
					<Messages
						onEdit={this.editMsg}
						onDelete={this.deleteMsg}
						onSave={this.saveMsg}
						onCancel={this.cancelMsg}
						onTyping={this.onTyping}
						onNewMessageChange={this.onNewMessageChange}

						newMessage={this.state.newMessage}
						editing={this.state.editing}
						updatingMsg={this.state.updatingMsg}
						deletingMsg={this.state.deletingMsg}
						waiting={this.state.waiting}
						cookieValue={this.state.cookieValue}
						msg={this.state.allMessages} />
				</div>
				<div id="bottom-msg"></div>
				<div className="relative">
					<div className="container lg:w-[768px] mx-auto flex flex-col justify-center pb-2 pt-2 px-4 relative flex-wrap">
						{
							!typing ? (
								<div className="flex flex-col w-full py-2 flex-grow md:py-3 md:pl-4 relative border border-black/10 bg-white dark:border-gray-900/50 dark:text-white dark:bg-gray-700 rounded-md shadow-[0_0_10px_rgba(0,0,0,0.10)] dark:shadow-[0_0_15px_rgba(0,0,0,0.10)] mb-2">
									<div>
										<TextareaAutosize
											maxRows={6}
											onKeyPress={this.handleKeyPress}
											id="userChat" name="userChat" onChange={this.handleChange} value={this.state.userChat}
											className="m-0 w-full resize-none border-0 bg-transparent p-0 pr-14 focus:ring-0 focus-visible:ring-0 pl-2 md:pl-0 outline-none"
											placeholder='Send a message...'
										/>
										<button id="send" onClick={() => this.send()} className='absolute right-3 bottom-2 rounded inline-flex items-center justify-center hover:bg-gray-100 w-8 h-8 text-gray-500 disabled:opacity-50 disabled:hover:bg-transparent'>
											<svg stroke="currentColor" fill="none" strokeWidth="2" viewBox="0 0 24 24" strokeLinecap="round" strokeLinejoin="round" className="h-5 w-5" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><line x1="22" y1="2" x2="11" y2="13"></line><polygon points="22 2 15 22 11 13 2 9 22 2"></polygon></svg>
										</button>
									</div>
								</div>
							) : ''
						}

						<div id={cookieValue} className="w-full text-center text-black/50 ">
							By messaging CAI, you are agreeing to our <a href='https://www.contrib.com/home/toc' className='font-semibold mx-1 hover:text-black'>Terms of Service</a> and <a href='https://www.contrib.com/home/privacy' className='font-semibold mx-1 hover:text-black'>Privacy Policy</a>.
						</div>
					</div>
				</div>
			</>
		)
	};
}

export default Chat;
